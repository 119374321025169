import React, { useState } from 'react';
import { Button, Col, Div, Flex, Hr, Span } from '../../style/styledElements';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledBtn } from '../../style/styledComponents';
import { initiatePayment } from './helpers';
import { useUserState } from '../../context/UserContext';
// import getepayPortal from '../../Getepay_pg_react';

const Card = styled(Div)`
    padding: 16px;
    background-color: #fbfbfb;
    border-radius: 16px;
    border: 1px solid #e2e2e2;
`;

function Ticket() {
    const { state } = useLocation();
    const { user } = useUserState();

    const { planType, planPrice: membershipPrice } = state;

    const [tickets, setTickets] = useState(0);

    const navigate = useNavigate();

    let membershipType;

    switch (Number(membershipPrice)) {
        case 100:
            membershipType = 3;
            break;
        case 5000:
            membershipType = 2;
            break;
        case 500:
            if (planType === 'regular') {
                membershipType = 1;
            } else {
                membershipType = 4;
            }
            break;

        default:
            break;
    }

    const checkout = (total) => {
        // getepayPortal(
        //     {
        //         mid: '108',
        //         amount: membershipPrice,
        //         merchantTransactionId: 'sd12121',
        //         transactionDate: new Date().toISOString(),
        //         terminalId: 'Getepay.merchant61062@icici',
        //         udf1: '1234567890',
        //         udf2: 'Test',
        //         udf3: 'test@gmail.com',
        //         ru: '',
        //         callbackUrl: '',
        //         currency: 'INR',
        //         paymentMode: 'ALL',
        //         bankId: '',
        //         txnType: 'single',
        //         productType: 'IPG',
        //         txnNote: 'Test Txn',
        //         vpa: 'Getepay.merchant61062@icici',
        //     },
        //     {
        //         'Getepay Terminal Id': 'Getepay.adaasd51021@icici',
        //         'Getepay Key': 'JoYPd+qso9s7T+Ebj8pi4Wl8i+AHLv+5UNJxA3JkDgY=',
        //         'Getepay IV': 'hlnuyA9b4YxDq6oJSZFl8g==',
        //         'Getepay Url':
        //             'http://164.52.216.34:8085/getepayPortal/pg/generateInvoice',
        //     }
        // );

        navigate('/payment', {
            state: {
                order_amount: total,
                order_currency: 'INR',
                ALUMNI_ID: user.ID,
                ALUMNI_EMAIL: user.EMAIL,
                ALUMNI_PHONE: String(user.PHONE),
                ALUMNI_NAME: user.NAME,
                no_of_ticket: tickets,
                membership: membershipPrice,
                membershipType,
            },
        });
    };

    const ticketPrice = tickets * 250;

    const total = membershipPrice + ticketPrice;

    console.log(membershipType);

    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    return (
        <>
            <Div fontSize='display2' textAlign='center' mt='4'>
                Your Total Amount to Pay Rs <b>{total}</b>
            </Div>
            <Flex justifyContent='center' mt='4' flexDirection='column'>
                <Div
                    width={{ xs: '100%' }}
                    py='4'
                    px={{ xs: '4', md: '5' }}
                    backgroundColor='white'
                    borderRadius='6'
                    border='1px solid #BABABA'
                >
                    <Div textAlign='center' fontSize='.95em' color='#4F4F4F'>
                        Your Order
                    </Div>
                    <Hr color='#EFE7EC' mt='3' mb='4' />

                    <Card mt='16px'>
                        <Flex fontSize='.95em' fontWeight='500'>
                            VOSA Membership
                            <Span ml='auto'>
                                {membershipPrice}
                                <Span color='#989898'>
                                    {' '}
                                    /{' '}
                                    {membershipType === 1 ||
                                    membershipType === 3
                                        ? 'Year'
                                        : 'Lifetime'}
                                </Span>
                            </Span>
                        </Flex>
                        <Flex fontSize={{ xs: 'small', md: '.7em' }}>
                            <Col width='25%' mt='2'>
                                Begins
                            </Col>
                            <Col width='75%' mt='2'>
                                {new Date().toLocaleDateString()}
                            </Col>
                            <Col width='25%' mt='2'>
                                Ends
                            </Col>
                            <Col width='75%' mt='2'>
                                {aYearFromNow.toLocaleDateString()}
                            </Col>
                            <Col width='25%' mt='2' fontWeight='500'>
                                Type
                            </Col>
                            <Col
                                width='25%'
                                fontWeight='500'
                                color='#0047FF'
                                mt='2'
                            >
                                {membershipType === 1 || membershipType === 3
                                    ? 'Year'
                                    : 'Lifetime'}
                            </Col>
                        </Flex>
                    </Card>
                    <Hr color='#EFE7EC' my='4' />

                    <Card>
                        <Flex fontSize='.95em' fontWeight='500'>
                            <Span>
                                Punarjini 2023 - 2024 <br />
                                Entry Ticket
                            </Span>
                            <Span ml='auto'>
                                250<Span color='#989898'> / Ticket</Span>
                            </Span>
                        </Flex>
                        <Div fontSize='.9em'>
                            <Col width='min-content' mr='4' ml='auto'>
                                Quantity
                            </Col>
                            <Flex
                                width='min-content'
                                ml='auto'
                                flexWrap='nowrap'
                                mt='2'
                            >
                                <Button
                                    lineHeight='13px'
                                    cursor='pointer'
                                    borderRadius='2'
                                    fontWeight='500'
                                    color='#0047FF'
                                    border='1px solid'
                                    onClick={() =>
                                        setTickets((prev) => prev - 1)
                                    }
                                    disabled={tickets === 0}
                                >
                                    &ndash;
                                </Button>
                                <Span mx='3'>{tickets}</Span>
                                <Button
                                    lineHeight='13px'
                                    cursor='pointer'
                                    borderRadius='2'
                                    fontWeight='500'
                                    color='#0047FF'
                                    border='1px solid'
                                    onClick={() =>
                                        setTickets((prev) => prev + 1)
                                    }
                                >
                                    &#43;
                                </Button>
                            </Flex>
                        </Div>
                    </Card>

                    <Flex px='4' mt='4' fontWeight='600'>
                        <Span>Total</Span>
                        <Span ml='auto'>₹ {total}</Span>
                    </Flex>
                </Div>
                <StyledBtn
                    mt='5'
                    borderRadius='5'
                    onClick={() => checkout(total)}
                >
                    Checkout
                </StyledBtn>
            </Flex>
        </>
    );
}

export default Ticket;
