import { Col, Div, Flex, Img, Link, Para, Span } from '../style/styledElements';
import Logo from '../asset/Logo.svg';
import { ReactComponent as Calender } from '../asset/calander.svg';
import { ReactComponent as Location } from '../asset/location.svg';
import GeethaLogo from '../asset/geethabhavantrust.png';
import Hurry from '../asset/Hurry.png';
import styled from 'styled-components';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useMediaType } from '../components/Responsiver';
import Footer from '../components/Footer';

const StyledDiv = styled(Div)`
    overflow: hidden;
    :after {
        content: '';
        position: absolute;
        bottom: ${(props) => (props.isMobile ? '-600px' : '-790px')};
        left: -25%;
        width: 150%;
        height: 1300px;
        background: #d3d3d359;
        border-radius: ${(props) => (props.isMobile ? '0' : '50%')};
        z-index: 1;
    }
`;

const Layout = (props) => {
    const navigate = useNavigate();
    const mainContent = useRef(null);
    const { pathname } = useLocation();

    const { isMobile } = useMediaType();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [pathname]);

    return (
        <StyledDiv
            background='linear-gradient(103.69deg, #E6EFEE 3.76%, #E2E9DF 93.56%)'
            pt={{ xs: '4', md: '7' }}
            pb={{ xs: '0', md: '9' }}
            position='relative'
            ref={mainContent}
            isMobile={isMobile}
        >
            <Flex
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                }}
                px={{ xs: '5', md: '10' }}
            >
                <Img
                    src={Logo}
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/')}
                    width={{ xs: '50%', md: '28%' }}
                />
                <Flex flexDirection='column' width={{ xs: '50%', md: '72%' }}>
                    <Div fontWeight='500' fontSize = {{xs:'.9em',md:'normal'}}>
                        VIDYADHIRAJA OLD STUDENTS’ ASSOCIATION (VOSA)
                    </Div>
                    <Div color='muted' mt='2' fontSize='small'>
                        Alumni Association of VVBHSS Aluva
                    </Div>
                </Flex>
                {/* <Img src={GeethaLogo} width={{ xs: '20%', md: '10%' }} /> */}
            </Flex>

            <Flex
                // mt='5'
                mx={{ xs: '5', md: '10' }}
                py={{ xs: '3', md: '5' }}
                px='4'
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#D3D3D359',
                    borderRadius: 32,
                }}
            >
                <Col
                    fontFamily='monospace'
                    fontWeight='700'
                    fontSize='display4'
                    pr='4'
                    order={{ xs: 1, md: 1 }}
                    width={{ xs: '70%', md: 'auto' }}
                >
                    PUNARJANI
                </Col>

                <Flex style={{ alignItems: 'center' }} order={{ xs: 3, md: 2 }}>
                    <Div pr='4'>
                        <Flex
                            width='48px'
                            height='48px'
                            background='#ffffff'
                            borderRadius='12px'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Calender />
                        </Flex>
                    </Div>
                    <Flex flexDirection='column'>
                        <Div fontWeight='500'>23rd JULY, 2023</Div>

                        <Para color='gray' fontSize='small' lineHeight='1.2'>
                            SUNDAY, 9:00AM - 4:30PM
                        </Para>
                    </Flex>
                </Flex>
                <Flex
                    style={{ alignItems: 'center' }}
                    order={{ xs: 4, md: 3 }}
                    my={{ xs: '2', md: '0' }}
                >
                    <Div pr='4'>
                        <Flex
                            width='48px'
                            height='48px'
                            background='#ffffff'
                            borderRadius='12px'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Location />
                        </Flex>
                    </Div>
                    <Flex flexDirection='column'>
                        <Div fontWeight='500'>VIDYADHIRAJA</Div>
                        <Div fontSize='small' color='gray' lineHeight='1.2'>
                            VIDYABHAVAN HSS, ALUVA
                        </Div>
                    </Flex>
                </Flex>
                <Col order={{ xs: 2, md: 4 }} width={{ xs: '30%', md: 'auto' }}>
                    <Img src={Hurry} width='100%' />
                </Col>
            </Flex>
            <Div zIndex='3' position='relative'>
                <Outlet />
            </Div>

            {pathname !== '/contact-us' && (
                <Flex
                    alignItems='center'
                    flexDirection='column'
                    zIndex='2'
                    position='relative'
                    mt='3'
                >
                    <NavLink to='contact-us' textDecoration='underline'>
                        Contact Us for queries
                    </NavLink>
                </Flex>
            )}
            <Footer />
        </StyledDiv>
    );
};

export default Layout;
