import React from 'react';
import { Div, H2 } from '../style/styledElements';

function About() {
    return (
        <Div py={{ xs: '3', md: '6' }} px={{ xs: '4', md: '10' }} lineHeight = '1.4'>
            <div>
                <Div  mb = '5' fontSize = '2em'>Geetha Bhavan Trust</Div>
                <p>
                    <strong>Geetha Bhavan Trust</strong>&nbsp; was founded in
                    the year 1973 by late Poojya Swami Gopalananda Theertha and
                    is registered under ‘The Charitable and Religious Trusts
                    Act, 1920’. &nbsp;The trust stands to build a scholarly
                    society and considers the society as a single entity.
                    <span></span>
                </p>
                <p>
                    Along with its inception in 1973, the Vidyadhiraja
                    Vidyabhavan English Medium High School, a State Syllabus
                    English Medium School was established at Cheerakkada , Aluva
                    under the vision of Swamiji. It was later upgraded to Higher
                    Secondary School in the year 2004.
                </p>
                <p>
                    Swamy Gopalananda Theertha Saraswathy Vidyanikethan High
                    School, a CBSE English Medium school was established in the
                    year 2002 at Nochima which was later upgraded to Senior
                    Secondary status in the year 2012. Latest addition came when
                    the trust acquired Asok Lower Primary School, a Government
                    Aided School at Asokapuram in the year 2010, from Asok
                    textiles when the same went to liquidation.
                </p>
            </div>
            <div>
                <p>
                    Apart from this the trust also runs Haidava Sevasram at
                    Nochima and Mariyamman Koil at Asokapuram.
                </p>
                <p>
                    The organization was always instrumental in establishing
                    educational institutions, spreading the faith in God, and
                    moulding future generations and believes in the holistic
                    development of society through students, especially from the
                    deprived ones.
                </p>
                <p>
                    Trust emphasizes that a drastic change in the educational
                    field can be made by developing the educational set up where
                    education is affordable without loosing the quality. The
                    health sector is also a prime concern of the trust and is
                    active in palliative and other social initiatives through
                    channel partners. The next big leap, addition of new
                    institutions is currently underway.
                </p>
                <p>
                    Currently the trust office is located at VVBHSS Campus,
                    Cheerakkada Road, Aluva.
                </p>
            </div>
        </Div>
    );
}

export default About;
