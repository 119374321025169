import * as Yup from 'yup';

const registerValidationSchema = Yup.object().shape({
    fullName: Yup.string().trim().required('Full name is required'),
    yearOfGraduation: Yup.number()
        .required('Year of graduation is required')
        .min(1900, 'Enter valid year')
        .max(new Date().getFullYear(), 'Enter valid year'),
    passingQualification: Yup.string()
        .trim()
        .required('Passing qualification is required'),
    contactNumber: Yup.string()
        // .min(1000000000, 'Pleaser enter a valid contact number')
        // .max(9999999999, 'Pleaser enter a valid contact number')
        .required('Contact number is required'),
    email: Yup.string()
        .email('Please enter a valid email address')
        // .matches(
        //     '[a-z0-9]+@[a-z]+.[a-z]{2,3}',
        //     'Please enter a valid email address'
        // )
        .trim()
        .required('Email is required'),

    dob: Yup.date()
        .max(
            new Date().getFullYear() - 12,
            'Date of birth must be at earlier than 2011'
        )
        .required('Date of birth is required'),
    currentLocation: Yup.string()
        .trim()
        .required('Current location is required'),
    isEmployed: Yup.string().trim().required('Employed status is required'),
    referenceClassmate: Yup.string()
        .trim()
        .required('Please refer any classmate'),
    organization: Yup.string().trim(),
    yearofExperience: Yup.number().min(0, 'Invalid value'),
    address: Yup.string().trim(),
});

export default registerValidationSchema;
