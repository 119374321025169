import React from 'react';
import { Div } from '../style/styledElements';

function Privacy() {
    return (
        <Div py={{ xs: '3', md: '6' }} px={{ xs: '4', md: '10' }}>
            <h2>
                <strong>Privacy Policy</strong>
            </h2>
            <p>
                Geetha Bhavan Trust operates the website "Punarjani" at
                https://vidyadhirajaalumni.com. We take your privacy seriously.
                To better protect your privacy, we provide this privacy policy
                notice explaining the way your personal information is collected
                and used.
            </p>
            <p>
                <strong>Collection of Routine Information</strong>
            </p>
            <p>
                This website track basic information about its visitors. This
                information includes, but is not limited to, IP addresses,
                browser details, timestamps and referring pages. None of this
                information can personally identify specific visitors to this
                website. The information is tracked for routine administration
                and maintenance purposes.
            </p>
            <p>
                <strong>Cookies</strong>
            </p>
            <p>
                Where necessary, this website uses cookies to store information
                about a visitor’s preferences and history to better serve the
                visitor and/or present the visitor with customized content.
            </p>
            <p>
                <strong>Advertisement and Other Third Parties</strong>
            </p>
            <p>
                Advertising partners and other third parties may use cookies,
                scripts and/or web beacons to track visitor activities on this
                website to display advertisements and other useful information.
                Such tracking is done directly by the third parties through
                their servers and is subject to their privacy policies. This
                website has no access or control over these cookies, scripts
                and/or web beacons that may be used by third parties.
            </p>
            <p>
                <strong>Security</strong>
            </p>
            <p>
                The security of your personal information is important to us,
                but remember that no method of transmission over the Internet,
                or method of electronic storage, is 100% secure. While we strive
                to use commercially acceptable means to protect your personal
                information, we cannot guarantee its absolute security.
            </p>
            <p>
                <strong>Changes To This Privacy Policy</strong>
            </p>
            <p>
                This Privacy Policy is effective as of 2023-06-09 and will
                remain in effect except concerning any changes in its provisions
                in the future, which will be in effect immediately after being
                posted on this page. We reserve the right to update or change
                our Privacy Policy at any time and you should check this Privacy
                Policy periodically. If we make any material changes to this
                Privacy Policy, we will notify you either through the email
                address you have provided us or by placing a prominent notice on
                our website.
            </p>
            <p>
                <strong>Contact Information</strong>
            </p>
            <p>
                For any questions or concerns regarding the privacy policy,
                please send us an email at{' '}
                <a href='mailto:alumnivvbhss@geethabhavantrust.org'>
                    alumnivvbhss@geethabhavantrust.org
                </a>
                .
            </p>
            <p>
                Our Address: Cheerakada Temple(RD), Aluva, Ernakulam-683101    
            </p>
        </Div>
    );
}

export default Privacy;
