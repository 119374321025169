import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from 'styled-components';
import theme from './style/theme';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SearchAlumni from './pages/search/SearchAlumni';
import Layout from './layout/Layout';
import Pricing from './pages/pricing/Index';
import RegisterUser from './pages/register/RegisterUser';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import About from './pages/About';
import ContactUs from './pages/contact/Index';
import CancelAndRefund from './pages/Cancel_Refund';
import Plan from './pages/pricing/Plan';
import Ticket from './pages/pricing/Ticket';
import { UserProvider } from './context/UserContext';
import ConfirmedOrder from './pages/confirmedOrder/Index';
import QRCode from './pages/QR/Index';
import Transactions from './pages/Transactions';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '',
                element: <SearchAlumni />,
            },
            {
                path: 'register',
                element: <RegisterUser />,
            },
            {
                path: 'pricing',
                element: <Pricing />,
                children: [
                    {
                        path: 'plan-selection',
                        element: <Plan />,
                    },
                    { path: 'ticket-purchase', element: <Ticket /> },
                ],
            },
            {
                path: 'order-summary',
                element: <ConfirmedOrder />,
            },
            {
                path: 'terms-and-condition',
                element: <Terms />,
            },
            {
                path: 'privacy-policy',
                element: <Privacy />,
            },
            {
                path: 'about-us',
                element: <About />,
            },
            {
                path: 'contact-us',
                element: <ContactUs />,
            },
            {
                path: 'cancel-refund',
                element: <CancelAndRefund />,
            },
            {
                path: 'payment',
                element: <QRCode />,
            },
        ],
    },
    {
        path: 'transactions',
        element: <Transactions />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <UserProvider>
                <RouterProvider router={router} />
            </UserProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
