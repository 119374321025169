import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    Div,
    Flex,
    Img,
    Input,
    Span,
} from '../../style/styledElements';
import QR from '../../asset/QRCODE.jpeg';
import { useEffect, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import API from '../../config/Api';

function QRCode() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        transactionId: '',
        upiPhone: '',
    });

    const confirmTransaction = (e) => {
        e.preventDefault();
        setLoading(true);
        const toastId = toast.loading('Loading');
        axios
            .post(API.confirmTransaction, {
                ALUMNI_ID: state.ALUMNI_ID,
                PLAN: state.membershipType,
                TICKETS: state.no_of_ticket,
                TOTAL_AMOUNT: state.order_amount,
                UPI_TRANSACTION_NUMBER: values.transactionId,
                UPI_PHONE_NUMBER: values.upiPhone || state.ALUMNI_PHONE,
            })
            .then((res) => {
                toast.success('Success', { id: toastId });
                setLoading(false);
                navigate('/order-summary', { state: state });
            })
            .catch((err) => {
                toast.error('Something went wrong', { id: toastId });
                setLoading(false);
            });
    };

    const onChange = ({ target }) => {
        const { name, value } = target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    return (
        <Flex justifyContent='center' mt='6'>
            <Toaster />
            <Div
                width={{ xs: '100%', md: '55%', lg: '45%', xl: '35%' }}
                p='5'
                borderRadius='6'
                bg='white'
                textAlign='center'
                boxShadow='0 0 15px lightgray'
            >
                <Div fontSize='display5'>Geetha Bhavan Trust</Div>
                <Div color='muted' mt='1'>
                    getepay.mbandhan98101@icici
                </Div>
                <Img
                    mt='3'
                    borderRadius='4'
                    src={QR}
                    width='100%'
                    display='block'
                />
                <Div
                    borderBottomRightRadius='4'
                    borderBottomLeftRadius='4'
                    mt='2'
                >
                    <Span fontSize='display5' fontWeight='500' color='primary'>
                        Scan & Pay
                    </Span>
                    <br />
                    <Span fontSize='.9em'>With any App</Span>
                </Div>
                <Div width='90%' mx='auto'>
                    <Div mt='5' fontSize='display6'>
                        <Span>Amount to pay </Span>
                        <Span ml='auto' color='' fontSize='display6'>
                            ₹{state.order_amount}
                        </Span>
                    </Div>
                    <form onSubmit={confirmTransaction}>
                        <Input
                            placeholder='UPI Phone'
                            fontSize='.9em'
                            value={values.upiPhone}
                            name='upiPhone'
                            onChange={onChange}
                            // required
                            readOnly={loading}
                            mt='3'
                        />
                        <Flex mt='2'>
                            <Input
                                width='85%'
                                placeholder='Transaction ID'
                                borderTopRightRadius='0'
                                borderBottomRightRadius='0'
                                fontSize='.9em'
                                value={values.transactionId}
                                name='transactionId'
                                required
                                onChange={onChange}
                                readOnly={loading}
                            />
                            <Button
                                width='15%'
                                borderTopLeftRadius='0'
                                borderBottomLeftRadius='0'
                                color='white'
                                bg='success'
                                border='none'
                                disabled={loading}
                            >
                                &#10004;
                            </Button>
                        </Flex>
                    </form>
                    <Div mt='2' fontSize='small' color='warning'>
                        To complete the registration process, please ensure you
                        provide your Transaction ID of the payment
                    </Div>
                </Div>
            </Div>
        </Flex>
    );
}

export default QRCode;
