import {
    Button,
    Col,
    Div,
    Flex,
    Label,
    Select,
} from '../../style/styledElements';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import searchValidationSchema from '../../validation/searchValidationSchema';
import axios from 'axios';
import API from '../../config/Api';
import toast, { Toaster } from 'react-hot-toast';
import { useState } from 'react';
import AutoInput from '../../components/AutoInput';
import { useNavigate } from 'react-router-dom';
import { StyledBtn } from '../../style/styledComponents';
import { useUserState } from '../../context/UserContext';

const SearchAlumni = () => {
    const navigate = useNavigate();
    const { setUser } = useUserState();
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [alumni, setAlumni] = useState([]);

    const getAlumniByYOP = (yop) => {
        setLoading(true);
        axios
            .get(API.searchAlumniByYOP + yop)
            .then((res) => {
                console.log(res);
                setAlumni(res.data.responseData);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const YearOfGrad = ({ field, form, ...props }) => {
        const arrayRange = (start, stop, step) =>
            Array.from(
                { length: (stop - start) / step + 1 },
                (value, index) => start + index * step
            );
        return (
            <Select
                {...field}
                {...props}
                style={{ fontSize: 17, paddingLeft: '5px' }}
            >
                <option value=''>Select Year</option>
                {arrayRange(1979, new Date().getFullYear(), 1).map((year) => (
                    <option value={year}>{year}</option>
                ))}
            </Select>
        );
    };

    const searchUser = (data, { setSubmitting }) => {
        let fullName;
        let alumId;

        if (data.fullName.includes(' : ')) {
            fullName = data.fullName.split(' : ')[1];
            alumId = data.fullName.split(' : ')[0];
        } else {
            fullName = data.fullName;
        }

        const formData = {
            FULL_NAME: fullName,
            YEAR_OF_PASSING: data.yearOfGraduation,
        };
        if (alumId) {
            formData.ALUMNI_ID = alumId;
        }

        const toastId = toast.loading('Submitting');

        let endpoint = API.searchAlumni + '/' + (alumId || '');

        axios
            .post(endpoint, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
            })
            .then((res) => {
                const userFound = res.data.responseData[0];

                if (userFound) {
                    if (userFound.PROFILE_STATUS === 'DEFAULT') {
                        navigate('register', { state: userFound });
                        toast.success('Record found', {
                            id: toastId,
                        });
                    } else {
                        if (Number(userFound.PAYMENT_STATUS) === 0) {
                            setUser({
                                ID: userFound.ALUMNI_ID,
                                EMAIL: userFound.EMAIL,
                                PHONE: userFound.PHONE,
                                NAME: userFound.FULL_NAME,
                                DOB: userFound.DOB,
                            });
                            toast.success('Choose plan', {
                                id: toastId,
                            });
                            navigate('pricing/plan-selection', {
                                state: userFound,
                            });
                        } else {
                            toast.success(
                                'Your registration have been already completed',
                                { id: toastId }
                            );
                            navigate('order-summary', {
                                state: userFound,
                            });
                        }
                    }
                } else {
                    toast.error('Your record not found, please register', {
                        id: toastId,
                    });
                    setNotFound(true);
                }
                setSubmitting(false);
            })
            .catch((err) => {
                toast.error('Something went wrong', {
                    id: toastId,
                });
                setSubmitting(false);
            });
    };

    return (
        <Flex justifyContent='center' mt='6'>
            <Div
                p={{ xs: '5', md: '6' }}
                background='white'
                borderRadius='6'
                textAlign='center'
                width={{ xs: '100%', md: '55%', lg: '45%', xl: '35%' }}
            >
                <Toaster />
                <Div fontWeight='500' fontSize='display3'>
                    Check your Alumni Status
                </Div>
                <Flex
                    style={{ flexDirection: 'column', alignItems: 'center' }}
                    width='100%'
                    mt='4'
                >
                    <Formik
                        initialValues={{ fullName: '', yearOfGraduation: '' }}
                        validationSchema={searchValidationSchema}
                        onSubmit={searchUser}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <Col
                                    width='100%'
                                    px='3'
                                    textAlign='start'
                                    mt='4'
                                >
                                    <Label
                                        display='inline-block'
                                        mb='1'
                                        pl='1'
                                        color='gray'
                                    >
                                        Year of Graduation
                                    </Label>
                                    <Field
                                        type='number'
                                        name='yearOfGraduation'
                                        placeholder='Year of Graduation'
                                        component={YearOfGrad}
                                        onChange={(e) => {
                                            setNotFound(false);
                                            setFieldValue(
                                                'yearOfGraduation',
                                                e.target.value
                                            );
                                            getAlumniByYOP(e.target.value);
                                        }}
                                        required
                                    />
                                    <ErrorMessage
                                        name='yearOfGraduation'
                                        render={(msg) => (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    fontSize: 14,
                                                    paddingTop: 10,
                                                }}
                                            >
                                                {msg}
                                            </div>
                                        )}
                                    />
                                </Col>
                                <Col
                                    width='100%'
                                    px='3'
                                    textAlign='start'
                                    mt='4'
                                >
                                    <Label
                                        display='inline-block'
                                        mb='1'
                                        pl='1'
                                        color='gray'
                                    >
                                        Full Name
                                    </Label>
                                    <AutoInput
                                        items={alumni}
                                        inputProps={{
                                            name: 'fullName',
                                            disabled:
                                                !values.yearOfGraduation ||
                                                loading,
                                            required: true,
                                            placeholder: loading
                                                ? 'Loading data...'
                                                : 'Your full name..',
                                            style: {fontSize: '.9em'},
                                        }}
                                        onSelect={(val) => {
                                            setFieldValue('fullName', val);
                                        }}
                                        value={values.fullName}
                                        onChange={(e) => {
                                            setNotFound(false);
                                            setFieldValue(
                                                'fullName',
                                                e.target.value
                                            );
                                        }}
                                    />

                                    <ErrorMessage
                                        name='fullName'
                                        render={(msg) => (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    fontSize: 14,
                                                    paddingTop: 10,
                                                }}
                                            >
                                                {msg}
                                            </div>
                                        )}
                                    />
                                </Col>
                                {notFound && (
                                    <Div mt='4' color='warning'>
                                        🤔 No Records Found ?
                                        <Button
                                            onClick={() => {
                                                navigate('register');
                                            }}
                                            ml='2'
                                            color='success'
                                            background='none'
                                            fontSize='normal'
                                            border='none'
                                            type='button'
                                        >
                                            Register
                                        </Button>
                                    </Div>
                                )}
                                <StyledBtn
                                    width='90%'
                                    mt='5'
                                    type='submit'
                                    borderRadius='5'
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </StyledBtn>
                            </Form>
                        )}
                    </Formik>
                </Flex>
            </Div>
        </Flex>
    );
};

export default SearchAlumni;
