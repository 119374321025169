const radii = [
    '0px',
    '2px',
    '4px',
    '8px',
    '10px',
    '16px',
    '24px',
    '36px',
    '48px',
    '90px',
];

const space = [0, 4, 10, 16, 24, 32, 48, 64, 128, 156, 200];

const theme = {
    breakpoints: {},

    space,
    fontSizes: {},
    // lineHeights,
    // fonts: {
    //     normal: "'Nunito', sans-serif",
    //     heading: "'Nunito Sans', sans-serif",
    // },

    colors: {
        primary: '#0047FF',
        warning: '#E97000',
        success: '#44B431',
        secondary: '#004D57',
        danger: '#FF3232',
        muted:'#808080'
    },

    radii,
};

const fontSizes = [
    '.75em',
    '1em',
    '1.1em',
    '1.2em',
    '1.4em',
    '1.6em',
    '1.8em',
    '2.5em',
    '3em',
];

theme.fontSizes.heading = fontSizes[8];
theme.fontSizes.display1 = fontSizes[7];
theme.fontSizes.display2 = fontSizes[6];
theme.fontSizes.display3 = fontSizes[5];
theme.fontSizes.display4 = fontSizes[4];
theme.fontSizes.display5 = fontSizes[3];
theme.fontSizes.display6 = fontSizes[2];
theme.fontSizes.normal = fontSizes[1];
theme.fontSizes.small = fontSizes[0];

theme.breakpoints.xs = '320px';
theme.breakpoints.sm = '480px';
theme.breakpoints.md = '767px';
theme.breakpoints.lg = '1025px';
theme.breakpoints.xl = '1200px';

export default theme;
