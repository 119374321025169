import {
    Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {
    Button,
    Col,
    Div,
    Flex,
    H2,
    Para,
    Span,
} from '../../style/styledElements';
import { useEffect } from 'react';
import axios from 'axios';
import API from '../../config/Api';
import styled from 'styled-components';
import Plan from './Plan';
import { useUserState } from '../../context/UserContext';

const Tab = styled(Div)`
    :before {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid
            ${(props) => (props.tabOneActive ? '#5533A5' : '#948989')};
        background-color: ${(props) =>
            props.tabOneActive ? '#5533A5' : '#948989'};
        position: absolute;
        left: 0;
        top: -5px;
    }
    :after {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid
            ${(props) => (props.tabTwoActive ? '#5533A5' : '#948989')};
        background-color: ${(props) =>
            props.tabTwoActive ? '#5533A5' : '#948989'};
        position: absolute;
        right: 0;
        top: -5px;
    }
`;

function Pricing() {

    const {pathname} = useLocation()
console.log(pathname)
    return (
        <Flex justifyContent='center'>
            <Div width={{ xs: '100%', md: '55%', lg: '45%', xl: '40%' }}>
                <Div mt='3'>
                    <Flex justifyContent='center'>
                        <Col width='50%' position='relative'>
                            <Tab
                                backgroundColor='#948989'
                                height='1px'
                                tabOneActive={
                                    pathname === '/pricing/plan-selection'
                                }
                                tabTwoActive={
                                    pathname === '/pricing/ticket-purchase'
                                }
                            />
                        </Col>
                    </Flex>
                    <Flex textAlign='center' mt='3' fontSize='small'>
                        <Col width='50%'>Choose Your Plan</Col>
                        <Col width='50%'>Billing & Event Ticket Purchase</Col>
                    </Flex>
                </Div>
                <Outlet />
            </Div>
        </Flex>
    );
}

export default Pricing;
