import React from 'react';
import { Div, H2 } from '../style/styledElements';

function CancelAndRefund() {
    return (
        <Div
            py={{ xs: '3', md: '7' }}
            px={{ xs: '4', md: '10' }}
            lineHeight='1.4'
        >
            <div>
                <Div mb='5' fontSize='2em' textAlign = 'center'>
                    Cancelation and Refund Policy
                </Div>
                <p>
                    Due to limited seating capacity and other reasons, we
                    request that you cancel your registration in case of
                    inconveniences, at least 5days before a scheduled Alumni
                    Meet / General Body. Regarding the Annual Membership / Life
                    Membership no refund will be provided if a person chooses to
                    quit out of the organisation membership. You may cancel the
                    membership / registration by email to
                    vvbhssalumni@geethabhavantrust.org No refunds are given for
                    general body / meets but the executive committee from time
                    to time reviews this and could give the refunds at their
                    discretion, which is not a mandatory action
                </p>
            </div>
        </Div>
    );
}

export default CancelAndRefund;
