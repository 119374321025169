import styled from 'styled-components';
import { Button } from './styledElements';
import theme from './theme';

const StyledBtn = styled(Button)`
    background-color: ${theme.colors.primary};
    border: none;
    color: white;
    font-weight: 300;
    padding: 12px 0;
`;

export {StyledBtn} 