import { StyledBtn } from '../../style/styledComponents';
import {
    Button,
    Col,
    Div,
    Flex,
    Li,
    Para,
    Span,
    Ul,
} from '../../style/styledElements';
import Tick from '../../asset/Tick.svg';
import { useUserState } from '../../context/UserContext';
import { initiatePayment } from './helpers';
import { useNavigate } from 'react-router-dom';

function Plan() {
    const navigate = useNavigate();
    const { user, setUser } = useUserState();

    const plans = {
        student: {
            yearly: 100,
            lifetime: 500,
        },
        regular: {
            yearly: 500,
            lifetime: 5000,
        },
    };

    const planType =
        new Date().getFullYear() - new Date(user.DOB).getFullYear() > 23
            ? 'regular'
            : 'student';

    const issuedPlan = plans[planType];

    const choosePlan = (planPrice) => {
        navigate('/pricing/ticket-purchase', {
            state: { planPrice, planType },
        });
    };

    return (
        <Div mt='4'>
            <Div fontSize='display2' fontWeight='500' textAlign='center'>
                Our Pricing Designed For <Span color='#0047FF'>You</Span>
            </Div>
            <Para textAlign='center' mt='2' fontSize='small' color='#848199'>
                No surprise fees.
            </Para>
            <Flex mt='5' px={{ xs: '2', md: '0' }}>
                <Col width='50%' pr='2'>
                    <Div
                        backgroundColor='white'
                        borderRadius='6'
                        border='1px solid #848199'
                        p='4'
                    >
                        <Div fontWeight='500' fontSize='small' color='#0047FF'>
                            Annual
                        </Div>
                        <Div
                            mt='2'
                            display='flex'
                            style={{ alignItems: 'center' }}
                        >
                            <Span
                                fontSize='display2'
                                fontWeight='500'
                                color='#0047FF'
                            >
                                {issuedPlan.yearly}
                            </Span>
                            <Span color='#848199' ml='2' fontSize='small'>
                                / Year
                            </Span>
                        </Div>
                        <StyledBtn
                            borderRadius='4'
                            mt='3'
                            width='100%'
                            onClick={() => choosePlan(issuedPlan.yearly)}
                        >
                            Choose Plan
                        </StyledBtn>
                    </Div>
                </Col>
                <Col width='50%' pl='2'>
                    <Div backgroundColor='#252525' borderRadius='6' p='4'>
                        <Div fontWeight='500' fontSize='small' color='#FFD600'>
                            Lifetime
                        </Div>
                        <Div
                            mt='2'
                            display='flex'
                            style={{ alignItems: 'center' }}
                        >
                            <Span
                                fontSize='display2'
                                fontWeight='500'
                                color='white'
                            >
                                {issuedPlan.lifetime}
                            </Span>
                            {/* <Span color='#848199' ml='2' fontSize='small'>
                                / Year
                            </Span> */}
                        </Div>
                        <StyledBtn
                            borderRadius='4'
                            mt='3'
                            width='100%'
                            onClick={() => choosePlan(issuedPlan.lifetime)}
                        >
                            Choose Plan
                        </StyledBtn>
                    </Div>
                </Col>
            </Flex>
            <Div
                px='5'
                mt='4'
                borderRadius='5'
                py='4'
                border='1px solid #BABABA'
                fontSize='small'
                background='white'
                mx={{ xs: '2', md: '0' }}
            >
                <Div fontWeight='500'>What’s included</Div>
                <Ul pl='4' liImg={Tick}>
                    {benefits.map((benefit) => (
                        <Li mt='2' pl='2' key={benefit}>
                            {benefit}
                        </Li>
                    ))}
                </Ul>
            </Div>
        </Div>
    );
}

export default Plan;

const benefits = [
    'Subsidized treatment in hospitals and health care centers',
    'Discounted packages in diagnostic labs',
    'Tie-up with palliative care and home care establishments',
    'Tie-ups with old age homes',
    'Established relation with upfront supermarkets',
    'House maintenance and services',
    'Health and wellness consultants',
    'Home maid and housekeeping services',
    'Counsellors for the needed',
    'Business forums',
    'Doctors forums',
    'Placement services and skill upgradation schemes',
    'Gifts & momentos',
    'Job portals',
    'Career Consultancy',
    'Vehicle Maintenance',
    'Tutorial services',
    'Legal services',
    'Financial management support',
];
