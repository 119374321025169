import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

function UserProvider({ children }) {
    const [user, setUser] = useState({
        ID: '',
        EMAIL: '',
        PHONE: '',
        NAME: '',
    });
    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}

const useUserState = () => {
    let context = useContext(UserContext);
    if (context === undefined)
        throw new Error(
            'useUserState must be used within a UserProvider'
        );
    return context;
};

export { UserProvider, useUserState };
