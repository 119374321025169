import React from 'react';
import Autocomplete from 'react-autocomplete';
import { Div, Input } from '../style/styledElements';

function AutoInput({ value, ...rest }) {
    return (
        <Autocomplete
            getItemValue={(item) => item.ALUMNI_ID + ' : ' + item.FULL_NAME}
            shouldItemRender={(item, value) => {
                return (
                    item.FULL_NAME?.toLowerCase().indexOf(
                        value?.toLowerCase() ?? ''
                    ) > -1
                );
            }}
            wrapperStyle={{ width: '100%', position: 'relative' }}
            renderInput={(props) => <Input {...props} />}
            menuStyle={{
                borderRadius: '6px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                paddingTop: '2px',
                fontSize: '1em',
                position: 'absolute',
                top: '100%',
                left: 0,
                overflow: 'auto',
                maxHeight: '250px',
                textAlign: 'start',
                color: 'gray',
            }}
            renderItem={(item, isHighlighted) => (
                <Div background={isHighlighted ? 'lightgray' : 'white'} p='2'>
                    {item.ALUMNI_ID + ' : ' + item.FULL_NAME}
                </Div>
            )}
            value={value}
            {...rest}
        />
    );
}

export default AutoInput;
