import axios from 'axios';
import API from '../config/Api';
import { useEffect, useState } from 'react';
import { Div } from '../style/styledElements';
import { myExcelXML } from '../helpers';

function Transactions() {
    const [downloaded, setDownloaded] = useState(false);

    useEffect(() => {
        axios
            .get(API.listTransactions)
            .then((res) => {
                res.data.responseData?.forEach((data) => {
                    data.DOB = new Date(data.DOB).toDateString();
                    data.TRANSACTION_DATE = new Date(
                        data.TRANSACTION_DATE
                    ).toLocaleString('en-GB');
                });
                const excel = new myExcelXML(
                    JSON.stringify(res.data.responseData)
                );
                excel.downLoad();
                setDownloaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <Div fontSize='display4' mt='8' textAlign='center'>
            {downloaded
                ? 'Successfully downloaded'
                : 'Transactions are being downloaded...'}
        </Div>
    );
}

export default Transactions;
