import React from 'react';
import {
    Button,
    Div,
    Flex,
    Input,
    Col,
    Textarea,
    Span,
    Ul,
    Li,
    Link,
} from '../../style/styledElements';
import { Toaster } from 'react-hot-toast';
import LocationV2 from '../../asset/LocationV2.svg';
import Call from '../../asset/Call.svg';
import Email from '../../asset/Email.svg';
import { ReactComponent as Calendar } from '../../asset/Calendar.svg';

// const WithIcon =

function ContactUs() {
    return (
        <Flex justifyContent='center'>
            <Flex width='100%' justifyContent='center'>
                <Div
                    p={{ xs: '5', md: '6' }}
                    mt='6'
                    width={{ xs: '80%', md: '35%' }}
                    background='white'
                    borderRadius='6'
                    textAlign='center'
                    position='relative'
                    zIndex='2'
                >
                    <Toaster />
                    <Div
                        fontWeight='500'
                        fontSize='display3'
                        textAlign='center'
                    >
                        Contact Us
                    </Div>
                    <form name='contact'>
                        <input type='hidden' name='form-name' value='contact' />

                        <Input
                            name='name'
                            placeholder='Full Name'
                            required
                            mt={{ xs: '3', md: '5' }}
                        />
                        <Input
                            name='email'
                            placeholder='Email'
                            type='email'
                            required
                            mt={{ xs: '3', md: '3' }}
                        />
                        <Textarea
                            name='message'
                            placeholder='Your Message'
                            required
                            mt={{ xs: '3', md: '3' }}
                            height='150px'
                            maxWidth='100%'
                            minWidth='100%'
                        />
                        <Button
                            mt='3'
                            width='100%'
                            border='none'
                            backgroundColor='#0047FF'
                            py='3'
                            fontSize='display6'
                            fontWeight='500'
                            borderRadius='5'
                            color='white'
                        >
                            Submit
                        </Button>
                    </form>
                </Div>
            </Flex>
            <Flex
                flexDirection='column'
                width={{ xs: '90%', md: '45%', lg: '35%', xxl: '30%' }}
            >
                <Div
                    mt='6'
                    border='1px solid rgba(0, 0, 0, 0.08)'
                    backgroundColor='rgba(255, 255, 255, 0.1)'
                    borderRadius='6'
                    py='2'
                    px='3'
                >
                    <Flex alignItems='center'>
                        <Calendar />
                        <Span ml='2' fontWeight='500'>
                            Working Hours
                        </Span>
                    </Flex>
                    <Flex
                        fontSize={{ xs: '.8em', md: '.9em' }}
                        mt='2'
                        fontWeight='500'
                    >
                        <Col width={{ xs: '40%', md: '35%' }}>
                            Monday - Friday
                        </Col>
                        <Col width='50%'>Saturday</Col>
                        <Col width={{ xs: '10%', md: '15%' }}>Sunday</Col>
                    </Flex>
                    <Flex
                        fontSize={{ xs: '.8em', md: '.85em' }}
                        fontWeight='500'
                        mt='2'
                    >
                        <Col width={{ xs: '40%', md: '35%' }} color='primary'>
                            9:30 AM - 3:30 PM
                        </Col>
                        <Col width='50%' color='primary'>
                            10:00 AM - 12 :00 PM
                        </Col>
                        <Col width={{ xs: '10%', md: '15%' }} color='danger'>
                            Closed
                        </Col>
                    </Flex>
                </Div>
                <Div
                    border='1px solid rgba(0, 0, 0, 0.08)'
                    backgroundColor='rgba(255, 255, 255, 0.1)'
                    borderRadius='6'
                    py='2'
                    px='3'
                    mt='3'
                >
                    <Div fontWeight='500'>Find Us !</Div>
                    <Ul fontSize='.85em' pl='4'>
                        <Li
                            mt='2'
                            style={{
                                listStyleImage: 'url(' + LocationV2 + ')',
                            }}
                        >
                            Geetha Bhavan Trust Cheerakada Temple(RD), <br />{' '}
                            Aluva, Ernakulam-683101
                        </Li>
                        <Li
                            mt='2'
                            style={{ listStyleImage: 'url(' + Call + ')' }}
                        >
                            <Link to='tel:9778414100'>9778414100</Link>
                        </Li>
                        <Li
                            mt='2'
                            style={{ listStyleImage: 'url(' + Email + ')' }}
                        >
                            <Link to='mailto:alumnivvbhss@geethabhavantrust.org'>
                                alumnivvbhss@geethabhavantrust.org
                            </Link>
                        </Li>
                    </Ul>
                </Div>
            </Flex>
        </Flex>
    );
}

export default ContactUs;
