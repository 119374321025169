import {
    Button,
    Col,
    Div,
    Flex,
    Input,
    Label,
    Select,
} from '../../style/styledElements';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import registerValidationSchema from '../../validation/registerValidationSchema';
import axios from 'axios';
import API from '../../config/Api';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { initiatePayment } from '../pricing/helpers';
import { useUserState } from '../../context/UserContext';

const RegisterUser = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const userData = state || {};

    const { setUser } = useUserState();

    const MyInput = ({ field, form, ...props }) => {
        return (
            <Input
                {...field}
                {...props}
                style={{ fontSize: 17, paddingLeft: 20 }}
            />
        );
    };

    const passingQualificationSelect = ({ field, form, ...props }) => {
        return (
            <Select
                {...field}
                {...props}
                style={{ fontSize: 17, paddingLeft: 20 }}
            >
                <option value=''>Select Passing Qualification</option>
                <option value='10TH'>10TH</option>
                <option value='12TH'>12TH</option>
            </Select>
        );
    };
    const isEmployedSelect = ({ field, form, ...props }) => {
        return (
            <Select
                {...field}
                {...props}
                style={{ fontSize: 17, paddingLeft: 20 }}
            >
                <option value=''>Employed or Not?</option>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
            </Select>
        );
    };
    const YearOfGrad = ({ field, form, ...props }) => {
        const arrayRange = (start, stop, step) =>
            Array.from(
                { length: (stop - start) / step + 1 },
                (value, index) => start + index * step
            );
        return (
            <Select
                {...field}
                {...props}
                style={{ fontSize: 17, paddingLeft: 20 }}
            >
                <option value=''>Select Year</option>
                {arrayRange(1979, new Date().getFullYear(), 1).map((year) => (
                    <option value={year}>{year}</option>
                ))}
            </Select>
        );
    };

    const submitUser = (values, { setSubmitting }) => {
        const formData = {
            FULL_NAME: values.fullName,
            EMAIL: values.email,
            PHONE: values.contactNumber,
            YEAR_OF_PASSING: values.yearOfGraduation,
            PASSING_QUALIFICATION: values.passingQualification,
            ADDRESS: values.address,
            EMPLOYED: values.isEmployed,
            ORGANIZATION: values.organization,
            YEARS_OF_EXPERIANCE: values.yearofExperience,
            CURRENT_LOCATION: values.currentLocation,
            DOB: values.dob,
            REFERENCE_CLASSMATES: values.referenceClassmate,
        };
        const toastId = toast.loading('Processing');
        const formType = userData.ALUMNI_ID ? 'update' : 'add';

        const endpoint =
            formType === 'update' ? API.updateAlumni : API.addAlumni;

        if (formType === 'update') {
            formData.ALUMNI_ID = userData.ALUMNI_ID;
        }

        axios
            .post(endpoint, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
            })
            .then((res) => {
                setSubmitting(false);

                if (!res?.data?.isError) {
                    toast.success(
                        'Successfully registered. Please choose plan',
                        {
                            id: toastId,
                        }
                    );

                    setUser({
                        ID:
                            formType === 'add'
                                ? String(res.data?.responseData?.insertId)
                                : formData.ALUMNI_ID,
                        EMAIL: formData.EMAIL,
                        PHONE: formData.PHONE,
                        NAME: formData.FULL_NAME,
                        DOB: formData.DOB,
                    });
                    navigate('/pricing/plan-selection');
                } else {
                    toast.error('Submission failed, try again later!', {
                        id: toastId,
                    });
                }
            })
            .catch((err) => {
                setSubmitting(false);

                toast.error(
                    err?.response?.data?.statusText || 'Something went wrong',
                    {
                        id: toastId,
                    }
                );
            });
    };

    return (
        <Div
            p={{ xs: '5', md: '6' }}
            mx={{ xs: '0', md: '10' }}
            mt='6'
            background='white'
            borderRadius='6'
            textAlign='center'
        >
            <Toaster />
            <Div fontWeight='500' fontSize='display3' color='#0F0F10'>
                Register your Alumni Profile
            </Div>
            <Div color='#B9B8B8' fontSize='14' fontWeight='400' mt='2'>
                These Credentials are for your Admission Pass
            </Div>

            <Formik
                initialValues={{
                    fullName: userData.FULL_NAME ?? '',
                    yearOfGraduation: userData.YEAR_OF_PASSING ?? '',
                    passingQualification: userData.PASSING_QUALIFICATION ?? '',
                    contactNumber: userData.PHONE ?? '',
                    email: userData.EMAIL ?? '',
                    dob: userData?.DOB?.slice(0, 10) ?? '',
                    isEmployed: userData.EMPLOYED ?? '',
                    organization: userData.ORGANIZATION ?? '',
                    referenceClassmate: userData.referenceClassmate ?? '', //
                    currentLocation: userData.CURRENT_LOCATION ?? '',
                    address: userData.ADDRESS ?? '',
                    yearofExperience: userData.YEARS_OF_EXPERIANCE ?? '',
                }}
                validationSchema={registerValidationSchema}
                onSubmit={submitUser}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <Flex
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            width='100%'
                            mt={{ xs: '4', md: '6' }}
                        >
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Full Name
                                </Label>
                                <Field
                                    type='text'
                                    name='fullName'
                                    placeholder='Full Name'
                                    component={MyInput}
                                    disabled={!!userData.FULL_NAME}
                                />
                                <ErrorMessage
                                    name='fullName'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Year of Graduation
                                </Label>
                                <Field
                                    type='number'
                                    name='yearOfGraduation'
                                    placeholder='Year of Graduation'
                                    component={YearOfGrad}
                                    min='1900'
                                    disabled={!!userData.YEAR_OF_PASSING}
                                />
                                <ErrorMessage
                                    name='yearOfGraduation'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Passing Qualification
                                </Label>
                                <Field
                                    type='text'
                                    name='passingQualification'
                                    placeholder='Passing Qualification'
                                    component={passingQualificationSelect}
                                />
                                <ErrorMessage
                                    name='passingQualification'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>

                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Contact Number
                                </Label>
                                <Field
                                    // type='number'
                                    name='contactNumber'
                                    placeholder='Contact Number'
                                    component={MyInput}
                                />
                                <ErrorMessage
                                    name='contactNumber'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    E-mail
                                </Label>
                                <Field
                                    type='email'
                                    name='email'
                                    placeholder='E-mail'
                                    component={MyInput}
                                />
                                <ErrorMessage
                                    name='email'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Date of Birth
                                </Label>
                                <Field
                                    type='date'
                                    name='dob'
                                    placeholder='Date of Birth'
                                    component={MyInput}
                                />
                                <ErrorMessage
                                    name='dob'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>

                            {/* ThirdRow */}

                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Address
                                </Label>
                                <Field
                                    type='text'
                                    name='address'
                                    placeholder='Address'
                                    component={MyInput}
                                />
                                <ErrorMessage
                                    name='address'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Current Location
                                </Label>
                                <Field
                                    type='text'
                                    name='currentLocation'
                                    placeholder='Current Location'
                                    component={MyInput}
                                />
                                <ErrorMessage
                                    name='currentLocation'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Reference Classmates
                                </Label>
                                <Field
                                    type='text'
                                    name='referenceClassmate'
                                    placeholder='Reference Classmates'
                                    component={MyInput}
                                />
                                <ErrorMessage
                                    name='referenceClassmate'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            <Col
                                width={{ xs: '100%', md: '33%' }}
                                px='3'
                                textAlign='start'
                                mt='4'
                            >
                                <Label
                                    display='inline-block'
                                    mb='1'
                                    pl='1'
                                    color='gray'
                                >
                                    Employed ?
                                </Label>
                                <Field
                                    type='text'
                                    name='isEmployed'
                                    placeholder='Employed ?'
                                    component={isEmployedSelect}
                                />
                                <ErrorMessage
                                    name='isEmployed'
                                    render={(msg) => (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                paddingTop: 10,
                                            }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                />
                            </Col>
                            {Number(values.isEmployed) === 1 && (
                                <>
                                    <Col
                                        width={{ xs: '100%', md: '33%' }}
                                        px='3'
                                        textAlign='start'
                                        mt='4'
                                    >
                                        <Label
                                            display='inline-block'
                                            mb='1'
                                            pl='1'
                                            color='gray'
                                        >
                                            Organization
                                        </Label>
                                        <Field
                                            type='text'
                                            name='organization'
                                            placeholder='Organization'
                                            component={MyInput}
                                        />
                                        <ErrorMessage
                                            name='organization'
                                            render={(msg) => (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontSize: 14,
                                                        paddingTop: 10,
                                                    }}
                                                >
                                                    {msg}
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col
                                        width={{ xs: '100%', md: '33%' }}
                                        px='3'
                                        textAlign='start'
                                        mt='4'
                                    >
                                        <Label
                                            display='inline-block'
                                            mb='1'
                                            pl='1'
                                            color='gray'
                                        >
                                            Years of Experience
                                        </Label>
                                        <Field
                                            type='number'
                                            name='yearofExperience'
                                            placeholder='Years of Experience'
                                            component={MyInput}
                                        />
                                        <ErrorMessage
                                            name='yearofExperience'
                                            render={(msg) => (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontSize: 14,
                                                        paddingTop: 10,
                                                    }}
                                                >
                                                    {msg}
                                                </div>
                                            )}
                                        />
                                    </Col>
                                </>
                            )}
                        </Flex>
                        <Button
                            width={{ xs: '100%', md: '50%' }}
                            backgroundColor='#4CE45B'
                            border='none'
                            color='white'
                            fontWeight='300'
                            mt={{ xs: '6', md: '7' }}
                            fontSize='display5'
                            py='3'
                            borderRadius='20px'
                            type='submit'
                            disabled={isSubmitting}
                        >
                            Register
                        </Button>
                        <Div mt='6' color='#0047FF'>
                            The event is within your reach with just a click 🤩
                        </Div>
                        <Button
                            mt='4'
                            onClick={() => {
                                navigate('/');
                            }}
                            border='1px solid gray'
                            px='3'
                            type='button'
                            disabled={isSubmitting}
                        >
                            Back to Search
                        </Button>
                    </Form>
                )}
            </Formik>
        </Div>
    );
};

export default RegisterUser;
