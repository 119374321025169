import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../../config/Api';
import { Button, Div, Flex, Hr, Span } from '../../style/styledElements';
import { ReactComponent as Tick } from '../../asset/TickSuccess.svg';
import { Toaster } from 'react-hot-toast';

function ConfirmedOrder() {
    const {state} = useLocation();

    // const [data, setData] = useState({});
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const orderId = param.search.slice(param.search.indexOf('=') + 1);
    //     axios(API.getOrderDetails + orderId)
    //         .then((res) => {
    //             setData(res.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    //         .finally(() => setLoading(false));
    // }, []);
    
    return (
        <Flex justifyContent='center' mt='7'>
            <Toaster/>
            <Div
                background='white'
                borderRadius='6'
                width={{ xs: '100%', md: '55%', lg: '45%', xl: '35%' }}
                border='1px solid #848199'
                p='5'
            >
                <Div color='#23A26D' textAlign='center' fontSize='display2'>
                    Confirmation
                </Div>
                <Div
                    py='3'
                    textAlign='center'
                    borderRadius='4'
                    boxShadow='0px 8px 24px rgba(170, 170, 170, 0.12)'
                    width={{ xs: '100%', md: '70%' }}
                    mx='auto'
                    mt='4'
                >
                    <Flex
                        width='100px'
                        backgroundColor='#23A26D1F'
                        height='100px'
                        center
                        borderRadius='50%'
                        mx='auto'
                    >
                        <Tick />
                    </Flex>
                    <Div fontSize='small' mt='3'>
                        Payment Success!
                    </Div>
                    {/* <Div fontSize='display6' fontWeight='600' mt='1'>
                        INR {state.order_amount}
                    </Div> */}
                </Div>

                {/* <Div
                    p='3'
                    width={{ xs: '100%', md: '70%' }}
                    mx='auto'
                    borderRadius='4'
                    mt='4'
                    fontSize='small'
                    boxShadow='0px 8px 24px rgba(170, 170, 170, 0.12)'
                >
                   <Div fontWeight='500'>Payment Details</Div>
                   <Flex mt='2' fontSize='.9em'>
                        <Span color='gray'>Ref Number</Span>
                        <Span ml='auto'>{data.cf_order_id}7</Span>
                    </Flex>
                   <Flex mt='1' fontSize='.9em'>
                        <Span color='gray'>Payment Status</Span>
                        <Span ml='auto'>Success</Span>
                    </Flex>
                   <Flex mt='1' fontSize='.9em'>
                        <Span color='gray'>Payment Time</Span>
                        <Span ml='auto'>
                            {new Date(data.created_at).toDateString()}
                        </Span>
                    </Flex>
                   <Hr my='3' color='gray' />
                   <Flex
                        mt='3'
                        fontSize='.9em'
                        borderTop='1px dashed #EDEDED'
                        pt='3'
                    >
                        <Span color='gray'>Total Payment</Span>
                        <Span ml='auto' fontWeight='500'>
                            INR {state.order_amount}
                        </Span>
                    </Flex>
                </Div> 
               <Div
                    p='4'
                    borderRadius='5'
                    mt='5'
                    boxShadow='0px 8px 24px rgba(170, 170, 170, 0.12)'
                    fontSize='small'
                    textAlign='center'
                >
                    Check Your Inbox to Download the{' '}
                    <Span color='success'>Entry Tickets </Span>
                    and <Span color='success'> Invoice</Span>
                </Div>
                <Button
                    width='100%'
                    mt='5'
                    py='3'
                    color='#0047FF'
                    border='none'
                    backgroundColor='#0047FF24'
                >
                    Resend Tickets
                </Button>*/}
            </Div>
        </Flex>
    );
}

export default ConfirmedOrder;
