// const baseURL = 'http://134.209.157.187:3035/';
// const baseURL = 'http://192.168.1.14:3035/';
const baseURL = '/api/';
const API = {
    searchAlumni: baseURL + 'alumni/search',
    addAlumni: baseURL + 'alumni/add',
    updateAlumni: baseURL + 'alumni/update',
    searchAlumniByYOP: baseURL + 'alumni/',
    createOrder: baseURL + 'orders/createOrder',
    getOrderDetails: baseURL + 'orders/',
    confirmTransaction: baseURL + 'alumni/upi/addtransaction/',
    listTransactions:baseURL + 'alumni/list/transactions',
};
export default API;
