import React from 'react';
import { ReactComponent as Koble } from '../asset/Koble.svg';
import { Div, Flex, Link, Span } from '../style/styledElements';
import { ReactComponent as PoweredBy } from '../asset/Poweredby.svg';

function Footer() {
    return (
        <Flex
            position={{ xs: 'relative', md: 'absolute' }}
            bottom='0'
            width='100%'
            px={{ xs: '3', md: '7', xl: '9' }}
            py={{ xs: '6', md: '6' }}
            zIndex='3'
            alignItems='center'
            justifyContent='space-between'
            fontSize='.9em'
        >
            <Link
                width={{ xs: '50%', md: 'auto' }}
                to='terms-and-condition'
                borderRight={{ md: '2px solid grey' }}
                pr={{ md: '5' }}
                pl={{ xs: '0', md: '0' }}
                textAlign={{ xs: 'center', md: 'start' }}
            >
                Terms of use
            </Link>
            <Link
                width={{ xs: '50%', md: 'auto' }}
                to='privacy-policy'
                borderRight={{ md: '2px solid grey' }}
                px='5'
                textAlign={{ xs: 'center', md: 'start' }}
            >
                Privacy Policy
            </Link>
            <Link
                width={{ xs: '50%', md: 'auto' }}
                mt={{ xs: '5', md: '0' }}
                to='about-us'
                borderRight={{ md: '2px solid grey' }}
                px='5'
                textAlign={{ xs: 'center', md: 'start' }}
            >
                About Us
            </Link>
            <Link
                width={{ xs: '50%', md: 'auto' }}
                mt={{ xs: '5', md: '0' }}
                to='cancel-refund'
                borderRight={{ md: '2px solid grey' }}
                px='5'
                textAlign={{ xs: 'center', md: 'start' }}
            >
                Refund Policy
            </Link>
            <Div
                ml='auto'
                width={{ xs: '100%', md: 'auto' }}
                textAlign={{ xs: 'center', md: 'start' }}
                mt={{ xs: '5', md: '0' }}
            >
                <PoweredBy />
                <Span
                    ml='3'
                    cursor='pointer'
                    onClick={() => {
                        window.open('https://kobleapp.in/', '_blank')
                    }}
                >
                    <Koble />
                </Span>
            </Div>
        </Flex>
    );
}

export default Footer;
